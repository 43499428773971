import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import {
  addDataMicrosoftRealtime,
  addUserMicrosoftToCompanyFirestoreDB,
  createUserMicrosoftFirestoreDB,
  getQuestionsDataDB,
  getUserCompanyInfoDB,
  getUserInfoDB,
} from "../lib/db";
import { useNavigate } from "react-router-dom";
import "../styles/login.scss";
import LoadingSpinner from "./loadingSpinner";

import {
  getAuth,
  signInWithRedirect,
  OAuthProvider,
  getRedirectResult,
} from "firebase/auth";

function Login() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [user, setUser] = useState();
  const [userName, setUserName] = useState();
  const [userCompany, setUserCompany] = useState();
  const [userLang, setUserLang] = useState();
  const [userCompanyInfo, setUserCompanyInfo] = useState();
  const [questionsData, setQuestionsData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("isLoading_IRT")) {
      setIsLoading(true);
    }
    if (sessionStorage.getItem("id_IRT")) {
      setIsLoading(true);
      setUserInState();
    }
  }, []);

  useEffect(() => {
    if (user) {
      getUserInfo(user.uid);
    }
  }, [user, userCompany]); // eslint-disable-line

  useEffect(() => {
    if (userCompany) {
      getUserCompanyInfo();
    }
  }, [userCompany]); // eslint-disable-line

  useEffect(() => {
    if (userLang) {
      getQuestionsData(userCompany, userLang);
    }
  }, [userLang]); // eslint-disable-line

  useEffect(() => {
    if (user && userCompanyInfo && questionsData && email) {
      // const filterEmail = email.split("@")[1];
      // if (filterEmail === "vueling.com" || filterEmail === "col.vueling.com") {
      goToHome(userName, userCompany, userCompanyInfo, questionsData);

      // if (sessionStorage.getItem("result_redirect") === "ok") {
      //   setShowModalInvalidEmail(true);
      //   setIsLoading();
      //   sessionStorage.removeItem("id_IRT");
      //   sessionStorage.removeItem("email_IRT");
      //   sessionStorage.removeItem("isLoading_IRT");
      // }
    }
  }, [userName, userCompanyInfo, questionsData]); // eslint-disable-line

  const setUserInState = () => {
    setUser({ uid: sessionStorage.getItem("id_IRT") });
    setEmail(sessionStorage.getItem("email_IRT"));
  };

  const getUserInfo = async (userId) => {
    const data = await getUserInfoDB(userId);
    setUserName(data.name);
    setUserCompany(data.company);
    sessionStorage.setItem("userCompany_IRT", data.company);
    if (data.lang) {
      setUserLang(data.lang);
      sessionStorage.setItem("userLang_IRT", data.lang);
    } else {
      setUserLang("en");
      sessionStorage.setItem("userLang_IRT", "en");
    }
  };

  const getUserCompanyInfo = async () => {
    const data = await getUserCompanyInfoDB(userCompany);
    setUserCompanyInfo(data);
  };

  const getQuestionsData = async () => {
    const data = await getQuestionsDataDB(userCompany, userLang);
    setQuestionsData(data);
  };

  const goToHome = (userName, userCompany, userCompanyInfo, questionsData) => {
    navigate("/home", {
      state: {
        user: {
          userId: user.uid,
          userEmail: email,
          userName: userName,
          userCompany: userCompany,
          userCompanyInfo: userCompanyInfo,
        },
        questionsData: questionsData,
      },
    });
  };

  const handleMicrosoftLogin = async () => {
    sessionStorage.removeItem("result_redirect");
    sessionStorage.setItem("isLoading_IRT", true);
    setIsLoading(true);
    const auth = getAuth();
    const provider = new OAuthProvider("microsoft.com");
    signInWithRedirect(auth, provider);
    // setIsLoading(false);
  };

  const auth = getAuth();
  getRedirectResult(auth)
    .then(async (result) => {
      if (result) {
        sessionStorage.setItem("id_IRT", result.user.uid);
        sessionStorage.setItem("email_IRT", result.user.email);
        await createUserMicrosoftFirestore(result);
        window.location.reload(false);
        sessionStorage.setItem("result_redirect", "ok");
      }
    })
    .catch((error) => {
      sessionStorage.removeItem("isLoading_IRT");
      console.log(error);
    });

  const createUserMicrosoftFirestore = async (result) => {
    await createUserMicrosoftFirestoreDB(
      result.user.uid,
      result.user.providerData[0].displayName
    );
    await addUserMicrosoftToCompanyFirestoreDB(result.user.uid);
    await addDataMicrosoftRealtime(result.user.uid, result);
    console.log("user uid", result.user.uid);
  };

  return (
    <div className="login-div">
      <Navbar userLang={userLang}/>
      <div className="form-div">
        <div>
          <h2 className="form-title">SIGN IN</h2>
          <hr className="form-line"></hr>
          <div className="form-inputs-div">
            <div className="form-submit-div">
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <button
                  onClick={handleMicrosoftLogin}
                  className="btn-microsoft btn-accent form-submit-btn"
                >
                  Sing in with Microsoft
                </button>
              )}
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Login;
