import React, { useState } from "react";

function QuizGroupsQuestions({
  userData,
  questions,
  setCurrentQuestionsGroup,
  setUserTypeForm,
  isActiveQuestionsGroups,
  setIsActiveQuestionsGroups,
  setIsActiveVotingGroups,
  userLang,
}) {
  const [isActive, setisActive] = useState();
  const updateCurrentGroup = (groupNumber) => {
    setCurrentQuestionsGroup(groupNumber);
    setIsActiveQuestionsGroups(true);
    setIsActiveVotingGroups();
    setisActive(groupNumber);
  };
  return (
    <div>
      {questions?.map((question, key) => {
        let groupQuestionsNumber = Math.floor(key / 4 + 1);
        let questionNumber = key + 1;
        if (key % 4 === 0 && questionNumber < 40) {
          return (
            <div
              onClick={() => {
                updateCurrentGroup(key / 4 + 1);
                setUserTypeForm("questions");
                window.scrollTo(0, 0);
              }}
              className={
                groupQuestionsNumber === isActive && isActiveQuestionsGroups
                  ? "questions questions-selected text-body-2 color-grey-bg"
                  : "questions text-body-2 color-grey-bg"
              }
              key={key}
            >
              <div>
                {groupQuestionsNumber < 10 ? 0 : null}
                {groupQuestionsNumber}.{" "}
                {userLang === "en" ? "Questions group" : "Grupo de preguntas"}
              </div>
              {userData && userData?.questions ? (
                userData?.questions[
                  groupQuestionsNumber < 10
                    ? "G0" + groupQuestionsNumber
                    : "G" + groupQuestionsNumber
                ]?.done === true ? (
                  <div>
                    <img
                      className="icon-done"
                      alt="check"
                      src="/assets/img/icon-done-green.png"
                    ></img>
                  </div>
                ) : userData?.questions[
                    groupQuestionsNumber < 10
                      ? "G0" + groupQuestionsNumber
                      : "G" + groupQuestionsNumber
                  ] ? (
                  <span>
                    {
                      Object.keys(
                        userData?.questions[
                          groupQuestionsNumber < 10
                            ? "G0" + groupQuestionsNumber
                            : "G" + groupQuestionsNumber
                        ]
                      ).length
                    }{" "}
                    <span> {userLang === "en" ? "of" : "de"} 4</span>
                  </span>
                ) : (
                  <div className="empty-div"></div>
                )
              ) : (
                <div className="empty-div"></div>
              )}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}

export default QuizGroupsQuestions;
