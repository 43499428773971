import React from "react";
import "../styles/modal-new-quiz.scss";
const ModalNewQuiz = ({
  goToQuiz,
  userInfo,
  setShowModalStartQuiz,
  userLang,
}) => {
  return (
    <div className="modal">
      <div className="modal-main">
        <p className="modal-text">
          {userLang === "en"
            ? "By restarting this quiz, you are going to delete previous answers. Are you sure to continue?"
            : "Al reiniciar este cuestionario, borrará las respuestas anteriores. ¿Estás seguro de continuar?"}
        </p>
        <div className="modal-btns-div">
          <button
            onClick={() => {
              goToQuiz("new", userInfo);
              setShowModalStartQuiz();
            }}
            className="btn-primary-m btn-options-quiz"
          >
            {userLang === "en" ? "YES" : "SI"}
          </button>
          <button
            onClick={() => {
              setShowModalStartQuiz();
            }}
            className="btn-accent btn-options-quiz"
          >
            NO
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalNewQuiz;
