import React from "react";

import "../styles/quiz-form.scss";
import QuizFormOptions from "./quiz-form-options";
import QuizFormQuestions from "./quiz-form-questions";

function QuizForm({
  userInfo,
  userStateForm,
  currentQuestionsGroup,
  currentOptionsGroup,
  userTypeForm,
  questionsData,
  userData,
  setUserData,
  setQuestionData,
  setUserLang,
  userLang,
  getImgsUrl
}) {
  return (
    <div>
      <div className="hr-div">
        <hr />
      </div>
      <div className="quiz-form-div">
        {userTypeForm === "questions" ? (
          <QuizFormQuestions
            userInfo={userInfo}
            userData={userData}
            userStateForm={userStateForm}
            questionsData={questionsData}
            currentQuestionsGroup={currentQuestionsGroup}
            currentOptionsGroup={currentOptionsGroup}
            userTypeForm={userTypeForm}
            setUserData={setUserData}
            userLang={userLang}
          />
        ) : userTypeForm === "options" ? (
          <QuizFormOptions
            userInfo={userInfo}
            userData={userData}
            userStateForm={userStateForm}
            questionsData={questionsData}
            currentQuestionsGroup={currentQuestionsGroup}
            currentOptionsGroup={currentOptionsGroup}
            userTypeForm={userTypeForm}
            setUserData={setUserData}
            userLang={userLang}
          />
        ) : (
          <h4>
            {userLang === "en"
              ? "Select a questions group to"
              : "Seleccione un grupo de preguntas para"}{" "}
            {userStateForm === "new"
              ? userLang === "en"
                ? "start"
                : "empezar"
              : userLang === "en"
              ? "continue"
              : "continuar"}{" "}
            {userLang === "en" ? "the quiz" : "el cuestionario"}
          </h4>
        )}
      </div>
    </div>
  );
}

export default QuizForm;
