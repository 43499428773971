import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/login.scss";
import "../styles/home.scss";
import Navbar from "./navbar";
import ModalNewQuiz from "./modal-new-quiz";
import ModalEditProfile from "./modal-edit-profile";
import { getUserDepartamentDB } from "../lib/db";
function Home() {
  const [user, setuser] = useState();
  const [userLang, setUserLang] = useState();
  const [questionsData, setQuestionData] = useState();
  const [userInfo, setUserInfo] = useState();
  const [showModalStartQuiz, setShowModalStartQuiz] = useState();
  const [userGender, setUserGender] = useState("MALE");
  const [userAge, setUserAge] = useState("FROM 20 TO 30");
  const [userBusiness, setUserBusiness] = useState("CONSTRUCÍA");
  const [userDepartament, setUserDepartament] = useState("BUSINESS MANAGER");
  const [showModalDepartament, setShowModalDepartament] = useState();
  const location = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    if (location.state.user) {
      setuser(location.state.user);
      sessionStorage.removeItem("id_IRT");
      sessionStorage.removeItem("email_IRT");
      sessionStorage.removeItem("isLoading_IRT");
      getUserDepartament(
        location.state.user.userId,
        location.state.user.userCompany
      );
    }
    if (location.state.questionsData) {
      setQuestionData(location.state.questionsData);
    }
  }, [location]);

  useEffect(() => {
    if (user && user.userCompanyInfo && questionsData) {
      setUserInfo({
        id: user.userId,
        email: user.userEmail,
        name: user.userName,
        companyName: user.userCompanyInfo.name,
        companyId: user.userCompanyInfo.id,
        lang: sessionStorage.getItem("userLang_IRT"),
      });
      setUserLang(sessionStorage.getItem("userLang_IRT"));
    }
  }, [user, questionsData]);

  const goToQuiz = (userFormState, userInfo) => {
    navigate("/quiz", {
      state: {
        user: {
          userInfo: userInfo,
          userDepartament: userDepartament,
          userAge: userAge,
          userBusiness: userBusiness,
          userGender: userGender,
          userStateForm: userFormState,
        },
        questionsData: questionsData,
      },
    });
  };

  const getUserDepartament = async (userId, userCompany) => {
    const data = await getUserDepartamentDB(userId, userCompany);
    if (Object.values(data).length > 0) {
      setUserDepartament(data.departament);
      setUserAge(data.age);
      setUserBusiness(data.business);
      setUserGender(data.gender);
    } else {
      setShowModalDepartament(true);
    }
  };

  const getImgsUrl = async () => {
    Object.values(location.state.questionsData.questions).map(
      async (question) => {
        if (question.image) {
          question.image = await getImgsUrl(question.image);
        }
      }
    );
    setQuestionData(location.state.questionsData);
  };

  return (
    <div className="home-div">
      {showModalStartQuiz ? (
        <ModalNewQuiz
          goToQuiz={goToQuiz}
          userInfo={userInfo}
          setShowModalStartQuiz={setShowModalStartQuiz}
          userLang={userLang}
        />
      ) : null}
      {showModalDepartament ? (
        <ModalEditProfile
          userInfo={userInfo}
          setShowModalDepartament={setShowModalDepartament}
          userGenderProp={userGender}
          userAgeProp={userAge}
          userBusinessProp={userBusiness}
          userDepartamentProp={userDepartament}
          setUserGenderProp={setUserGender}
          setUserAgeProp={setUserAge}
          setUserBusinessProp={setUserBusiness}
          setUserDepartamentProp={setUserDepartament}
          userLang={userLang}
        />
      ) : null}
      {user && user.userName ? (
        <Navbar
          userName={user.userName}
          setShowModalDepartament={setShowModalDepartament}
          userLang={userLang}
          setQuestionData={setQuestionData}
          userInfo={userInfo}
          setUserLang={setUserLang}
          getImgsUrl={getImgsUrl}
        />
      ) : (
        <Navbar
          userName={"User"}
          setShowModalDepartament={setShowModalDepartament}
          userLang={userLang}
          setQuestionData={setQuestionData}
          userInfo={userInfo}
          setUserLang={setUserLang}
          getImgsUrl={getImgsUrl}
        />
      )}

      <div className="form-div">
        <img src="/assets/img/home.png" alt="home" />
        <div className="form-buttons">
          <button
            onClick={() => {
              setShowModalStartQuiz(true);
              // goToQuiz("new", userInfo);
            }}
            className="btn-primary-m btn-options-quiz"
          >
            {userLang === "en" ? "START QUIZ" : "COMENZAR CUESTIONARIO"}
          </button>
          <button
            onClick={() => {
              goToQuiz("continue", userInfo);
            }}
            className="btn-accent btn-options-quiz"
          >
            {userLang === "en" ? "CONTINUE QUIZ" : "CONTINUAR CUESTIONARIO"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
