import React, { useEffect, useState } from "react";
import { updateUserProfileDB } from "../lib/db";
import "../styles/modal-select-departament.scss";
import LoadingSpinner from "./loadingSpinner";
const ModalEditProfile = ({
  userInfo,
  setShowModalDepartament,
  userGenderProp,
  userAgeProp,
  userBusinessProp,
  userDepartamentProp,
  setUserGenderProp,
  setUserAgeProp,
  setUserBusinessProp,
  setUserDepartamentProp,
  userLang,
}) => {
  const [userGender, setUserGender] = useState(userGenderProp);
  const [userAge, setUserAge] = useState(userAgeProp);
  const [userBusiness, setUserBusiness] = useState(userBusinessProp);
  const [userDepartament, setUserDepartament] = useState(userDepartamentProp);
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    if (userInfo?.departament) {
      setUserDepartament(userInfo?.departament);
    }
  }, [userInfo]);

  useEffect(() => {
    if (
      userBusiness === "CONSTRUCÍA" ||
      userBusiness === "CONSTRUCÍA INSTALACIONES"
    ) {
      document.getElementById("select-title-user-departament").style.display =
        "block";
      document.getElementById("select-user-departament").style.display =
        "block";
    } else {
      document.getElementById("select-title-user-departament").style.display =
        "none";
      document.getElementById("select-user-departament").style.display = "none";
    }
    // if (
    //   userBusiness !== "CONSTRUCÍA" ||
    //   userBusiness !== "CONSTRUCÍA INSTALACIONES"
    // ) {
    //   setUserDepartament("-");
    // }
  }, [userBusiness]);

  const getUserDepartament = (event) => {
    setUserDepartament(event.target.value);
  };

  const getUserGender = (event) => {
    setUserGender(event.target.value);
  };

  const getUserAge = (event) => {
    setUserAge(event.target.value);
  };

  const getUserBusiness = (event) => {
    let business = event.target.value;
    setUserBusiness(business);
  };

  const updateUserProfile = async () => {
    if (userGender && userAge && userBusiness && userDepartament) {
      setIsLoading(true);
      await updateUserProfileDB(
        userInfo,
        userGender,
        userAge,
        userBusiness,
        userDepartament
      );
      if (setUserGenderProp) {
        setUserGenderProp(userGender);
      }
      if (setUserAgeProp) {
        setUserAgeProp(userAge);
      }
      if (setUserBusinessProp) {
        setUserBusinessProp(userBusiness);
      }
      if (setUserDepartamentProp) {
        setUserDepartamentProp(userDepartament);
      }
      setShowModalDepartament();
      setIsLoading();
    }
  };

  return (
    <div className="modal">
      <div className="modal-select-main">
        <div className="modal-select-div">
          <p className="modal-title">
            {" "}
            {userLang === "en" ? "SELECT GENDER" : "SELECCIONA GÉNERO"}
          </p>
          <select
            value={userGender}
            onChange={getUserGender}
            className="modal-select"
          >
            <option value="MALE">
              {userLang === "en" ? "MALE" : "MASCULINO"}
            </option>
            <option value="FEMALE">
              {userLang === "en" ? "FEMALE" : "FEMENINO"}
            </option>
            <option value="UNDEFINED">
              {userLang === "en" ? "UNDEFINED" : "SIN DEFINIR"}
            </option>
          </select>
          <p className="modal-title">
            {userLang === "en" ? "SELECT AGE" : "SELECCIONA EDAD"}
          </p>
          <select
            value={userAge}
            onChange={getUserAge}
            className="modal-select"
          >
            <option value="FROM 20 TO 30">
              {userLang === "en" ? "FROM 20 TO 30" : "DE 20 A 30"}
            </option>
            <option value="FROM 31 TO 40">
              {userLang === "en" ? "FROM 31 TO 40" : "DE 31 A 40"}
            </option>
            <option value="FROM 41 TO 50">
              {userLang === "en" ? "FROM 41 TO 50" : "DE 41 A 50"}
            </option>
            <option value="FROM 51 TO 60">
              {userLang === "en" ? "FROM 51 TO 60" : "DE 50 A 61"}
            </option>
            <option value="MORE THAN 61">
              {userLang === "en" ? "MORE THAN 61" : "MÁS DE 61"}
            </option>
          </select>
          <p className="modal-title">
            {userLang === "en" ? "SELECT BUSINESS" : "SELECCIONA EMPRESA"}
          </p>
          <select
            value={userBusiness}
            onChange={getUserBusiness}
            className="modal-select"
          >
            <option value="CONSTRUCÍA">CONSTRUCÍA</option>
            <option value="CONSTRUCÍA INSTALACIONES">
              CONSTRUCÍA INSTALACIONES
            </option>
            <option value="CIRCULAR CAPITAL">CIRCULAR CAPITAL</option>
            <option value="EIG">EIG</option>
            <option value="DEAR PLANET">DEAR PLANET</option>
            <option value="CORPORATE/EXCELLENCE">
              CORPORATE/EXCELLENCE
            </option>
          </select>
          <p id="select-title-user-departament" className="modal-title">
            {userLang === "en"
              ? "SELECT DEPARTAMENT"
              : "SELECCIONA DEPARTAMENTO"}
          </p>
          <select
            id="select-user-departament"
            value={userDepartament}
            onChange={getUserDepartament}
            className="modal-select"
          >
            <option value="BUSINESS MANAGER">
              {userLang === "en" ? "BUSINESS MANAGER" : "GERENTE"}
            </option>
            <option value="COMMERCIAL">
              {userLang === "en" ? "COMMERCIAL" : "COMERCIAL"}
            </option>
            <option value="STUDIES">
              {userLang === "en" ? "STUDIES" : "ESTUDIOS"}
            </option>
            <option value="OPERATIONS">
              {userLang === "en" ? "OPERATIONS" : "OPERACIONES"}
            </option>
          </select>
          <div className="modal-select-btns-div">
            {!isLoading && (
              <div className="modal-profile-buttons-div">
                <button
                  onClick={updateUserProfile}
                  className="btn-primary-m btn-options-quiz"
                >
                  {userLang === "en" ? "SAVE" : "GUARDAR"}
                </button>
                {userGenderProp ? (
                  <button
                    onClick={() => setShowModalDepartament()}
                    className="btn-accent btn-options-quiz"
                  >
                    {userLang === "en" ? "CANCEL" : "CANCELAR"}
                  </button>
                ) : null}
              </div>
            )}
            {isLoading && (
              <div className="modal-profile-buttons-div">
                <LoadingSpinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditProfile;
