import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/quiz.scss";
import "../styles/login.scss";
import Navbar from "./navbar";
import QuizGroups from "./quiz-groups";
import QuizForm from "./quiz-form";
import { deleteUserResponsesDB, getImgUrl, getUserDataDB } from "../lib/db";
import ModalEditProfile from "./modal-edit-profile";

function Quiz() {
  const [user, setuser] = useState();
  const [userLang, setUserLang] = useState();
  const [questionsData, setQuestionData] = useState();
  const [userInfo, setUserInfo] = useState();
  const [userData, setUserData] = useState();
  const [userStateForm, setUserStateForm] = useState();
  const [userTypeForm, setUserTypeForm] = useState();
  const [currentQuestionsGroup, setCurrentQuestionsGroup] = useState();
  const [currentOptionsGroup, setCurrentOptionsGroup] = useState();
  const [showModalDepartament, setShowModalDepartament] = useState();
  const [userGender, setUserGender] = useState();
  const [userAge, setUserAge] = useState();
  const [userBusiness, setUserBusiness] = useState();
  const [userDepartament, setUserDepartament] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  document.onkeydown = function (e) {
    if (e.key === "F5") {
      navigate("/");
    }
  };

  useEffect(() => {
    if (location.state.user) {
      setuser(location.state.user);
    }
    if (location.state.questionsData) {
      getImgsUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (user) {
      setUserInfo(user.userInfo);
      setUserGender(user.userGender);
      setUserAge(user.userAge);
      setUserBusiness(user.userBusiness);
      setUserDepartament(user.userDepartament);
      setUserStateForm(user.userStateForm);
      setUserLang(sessionStorage.getItem("userLang_IRT"));
    }
  }, [user]);

  useEffect(() => {
    if (userStateForm === "new") {
      //delete old responses of user
      deleteUserResponses();
      setUserData({});
    } else {
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStateForm, userInfo]);

  useEffect(() => {
    // if (location.state.questionsData) {
    getImgsUrl();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLang]);

  useEffect(() => {
    if (userDepartament) {
      setUserInfo({ ...userInfo, departament: userDepartament });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDepartament]);

  const deleteUserResponses = async () => {
    await deleteUserResponsesDB(user.userInfo.id, user.userInfo.companyId);
  };

  const getUserData = async () => {
    if (userInfo) {
      setUserData(await getUserDataDB(userInfo.id, userInfo.companyId));
    }
  };

  const getImgsUrl = async () => {
    Object.values(location.state.questionsData.questions).map(
      async (question) => {
        if (question.image) {
          question.image = await getImgUrl(question.image);
        }
      }
    );
    setQuestionData(location.state.questionsData);
  };

  return (
    <div className="quiz-div">
      {user && user.userInfo ? (
        <Navbar
          bg="-white"
          userName={user.userInfo.name}
          setShowModalDepartament={setShowModalDepartament}
          userLang={userLang}
          setQuestionData={setQuestionData}
          userInfo={userInfo}
          setUserLang={setUserLang}
          getImgsUrl={getImgsUrl}
        />
      ) : (
        <Navbar
          bg="-white"
          userName={"User"}
          setShowModalDepartament={setShowModalDepartament}
          userLang={userLang}
          setQuestionData={setQuestionData}
          userInfo={userInfo}
          setUserLang={setUserLang}
          getImgsUrl={getImgsUrl}
        />
      )}
      {showModalDepartament ? (
        <ModalEditProfile
          userInfo={user.userInfo}
          setShowModalDepartament={setShowModalDepartament}
          userGenderProp={userGender}
          userAgeProp={userAge}
          userBusinessProp={userBusiness}
          userDepartamentProp={userDepartament}
          setUserGenderProp={setUserGender}
          setUserAgeProp={setUserAge}
          setUserBusinessProp={setUserBusiness}
          setUserDepartamentProp={setUserDepartament}
          userLang={userLang}
        />
      ) : null}
      <div className="quiz-body">
        <div className="quiz-groups-div">
          <QuizGroups
            userInfo={userInfo}
            userDataProp={userData}
            userStateForm={userStateForm}
            questionsData={questionsData}
            setCurrentQuestionsGroup={setCurrentQuestionsGroup}
            setCurrentOptionsGroup={setCurrentOptionsGroup}
            setUserTypeForm={setUserTypeForm}
            userLang={userLang}
          />
        </div>
        <div className="quiz-questions-div">
          <QuizForm
            userInfo={userInfo}
            userData={userData}
            userStateForm={userStateForm}
            questionsData={questionsData}
            setQuestionData={setQuestionData}
            currentQuestionsGroup={currentQuestionsGroup}
            currentOptionsGroup={currentOptionsGroup}
            userTypeForm={userTypeForm}
            setUserData={setUserData}
            setUserLang={setUserLang}
            userLang={userLang}
            getImgsUrl={getImgsUrl}
          />
        </div>
      </div>
    </div>
  );
}

export default Quiz;
