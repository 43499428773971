import firebaseConfig from "./firebase";
import "firebase/compat/database";
import { initializeApp } from "@firebase/app";

import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  OAuthProvider,
  signInWithRedirect,
} from "firebase/auth";
import { child, get, getDatabase, ref, update } from "firebase/database";
import {
  arrayUnion,
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref as refStorage,
  getDownloadURL,
} from "firebase/storage";

initializeApp(firebaseConfig);
const db = getDatabase();
const dbFirestore = getFirestore();
const auth = getAuth();
const storage = getStorage();
const companyName = "construcia";

export const singIn = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      return user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
};

export const getUserDataDB = async (userId, companyId) => {
  const dbRef = ref(getDatabase());
  return await get(child(dbRef, "usersData/" + userId + "/" + companyId))
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        return await snapshot.val();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
    });

  // let data;
  // const userDataRef = ref(db, "usersData/" + userId);
  // onValue(userDataRef, (snapshot) => {
  //   data = snapshot.val();
  //   return data;
  // });
  // if (data) {
  //   return data;
  // } else {
  //   return {};
  // }

  // let userData;
  // onValue(
  //   ref(db, "/usersData/" + userId),
  //   (snapshot) => {
  //     userData = snapshot.val();
  //   },
  //   {
  //     onlyOnce: false,
  //   }
  // );
  // if (userData) {
  //   return userData;
  // } else {
  //   return {};
  // }
};

export const getUserInfoDB = async (userId) => {
  const collectionRef = doc(dbFirestore, `users/${userId}`);
  const docSnap = await getDoc(collectionRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return { name: "", company: "" };
  }
};

export const getUserDepartamentDB = async (userId, userCompany) => {
  const dbRef = ref(getDatabase());
  return await get(child(dbRef, "usersData/" + userId + "/" + userCompany))
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        return await snapshot.val();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const updateUserProfileDB = async (
  userInfo,
  userGender,
  userAge,
  userBusiness,
  userDepartament
) => {
  await update(ref(db, `usersData/${userInfo.id}/${userInfo.companyId}`), {
    gender: userGender,
    age: userAge,
    business: userBusiness,
    departament: userDepartament,
  });

  const userRef = doc(dbFirestore, `users/${userInfo.id}`);
  await updateDoc(userRef, {
    gender: userGender,
    age: userAge,
    business: userBusiness,
    departament: userDepartament,
  });
};

export const updateLanguageUserDB = async (userInfo, userLang) => {
  await update(ref(db, `usersData/${userInfo.id}/${userInfo.companyId}`), {
    lang: userLang,
  });
  const userRef = doc(dbFirestore, `users/${userInfo.id}`);
  await updateDoc(userRef, {
    lang: userLang,
  });
};

export const getUserCompanyInfoDB = async (userCompany) => {
  const collectionRef = doc(dbFirestore, `companies/${userCompany}`);
  const docSnap = await getDoc(collectionRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return { id: "", name: "" };
  }
};

export const getQuestionsDataDB = async (companyId, lang) => {
  const dbRef = ref(getDatabase());
  return await get(child(dbRef, "questionsData/" + companyId + "/" + lang))
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        return await snapshot.val();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const saveResponsesDB = async (
  userId,
  companyId,
  questionsGroup,
  responses,
  type
) => {
  if (responses) {
    if (questionsGroup < 10) {
      questionsGroup = "0" + questionsGroup;
    }
    responses.map(async (response) => {
      await update(
        ref(
          db,
          `usersData/${userId}/${companyId}/${type}/G${questionsGroup}/${response.questionId}`
        ),
        {
          timestamp: response.timestamp,
          value: response.value,
          done: true,
        }
      );
    });

    const dbRef = ref(getDatabase());
    const numResponses = await get(
      child(
        dbRef,
        `usersData/${userId}/${companyId}/${type}/G${questionsGroup}/`
      )
    )
      .then(async (snapshot) => {
        if (snapshot.exists()) {
          return await snapshot.val();
        } else {
          return {};
        }
      })
      .catch((error) => {
        console.error(error);
      });
    if (Object.keys(numResponses).length >= 4) {
      await update(
        ref(db, `usersData/${userId}/${companyId}/${type}/G${questionsGroup}/`),
        {
          done: true,
        }
      );
    }
    console.log("Responses saved!");
  }
};

export const deleteUserResponsesDB = async (userId, companyId) => {
  await update(ref(db, `usersData/${userId}/${companyId}`), {
    questions: {},
    options: {},
  });
};

export const getImgUrl = async (img) => {
  return await getDownloadURL(refStorage(storage, img))
    .then((url) => {
      return url;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const handleMicrosoftLoginDB = async () => {
  // setLoader(prevState => ({...prevState, microsoftLoading: true}));
  const provider = new OAuthProvider("microsoft.com");
  return await signInWithPopup(auth, provider)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const handleMicrosoftDB = async () => {
  // setLoader(prevState => ({...prevState, microsoftLoading: true}));
  const provider = new OAuthProvider("microsoft.com");
  return await signInWithRedirect(auth, provider);
};

export const createUserMicrosoftFirestoreDB = async (userId, userName) => {
  const collectionRef = doc(dbFirestore, `users/${userId}`);
  const docSnap = await getDoc(collectionRef);
  if (docSnap.exists()) {
    console.log("user logged exist in DB");
    await updateDoc(doc(dbFirestore, "users", userId), {
      company: companyName,
      name: userName,
      id: userId,
    });
  } else {
    await setDoc(doc(dbFirestore, "users", userId), {
      name: userName,
      id: userId,
      company: companyName,
    });
    console.log("user created in db");
  }
};

export const addUserMicrosoftToCompanyFirestoreDB = async (userId) => {
  const companyRef = doc(dbFirestore, `companies/${companyName}`);
  await updateDoc(companyRef, {
    users: arrayUnion(userId),
  });
  console.log("user added to the company");
};
export const addDataMicrosoftRealtime = async (userId, data) => {
  console.log("Data Microsoft: ", data);
  await update(ref(db, `Microsoft/${userId}/`), {
    operationType: data.operationType,
    providerId: data.providerId,
    _tokenResponse: data._tokenResponse,
    user: {
      accessToken: data.user.accessToken,
      auth: {
        app: data.user.auth.app,
        config: data.user.auth.config,
        lastNotifiedUid: data.user.auth.lastNotifiedUid,
        _errorFactory: data.user.auth._errorFactory,
      },
      displayName: data.user.displayName,
      email: data.user.email,
      metadata: data.user.metadata,
      phoneNumber: data.user.phoneNumber,
      photoURL: data.user.photoURL,
      providerData: data.user.providerData,
      providerId: data.user.providerId,
      reloadListener: data.user.reloadListener,
      reloadUserInfo: data.user.reloadUserInfo,
      stsTokenManager: data.user.stsTokenManager,
      tenantId: data.user.tenantId,
      uid: data.user.uid,
    },
  });
  console.log("data added realtime");
};
