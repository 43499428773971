import React from "react";
import { Link } from "react-router-dom";
import "../styles/navbar.scss";
import Languages from "./languages";
function Navbar({
  userName,
  bg,
  setShowModalDepartament,
  userLang,
  setQuestionData,
  userInfo,
  setUserLang,
  getImgsUrl,
}) {
  return (
    <div className={bg ? `navbar-div${bg}` : "navbar-div"}>
      <div>
        <img
          className="img-logo"
          src="/assets/img/logo-navbar.png"
          alt="logo"
        />
      </div>
      <div>
        {userName ? (
          <div className="user-info-div">
            <div className="user-info">
              <p className="text-body-2" style={{color: "silver"}}>
                {userLang === "en" ? "Hi!" : "Hola!"} {userName}
              </p>
              <p
                onClick={() => setShowModalDepartament(true)}
                className="links"
              >
                {userLang === "en" ? "Change Profile" : "Editar Perfil"}
              </p>
              <Link to={"/login"}>
                {userLang === "en" ? "Log out" : "Cerrar Sesión"}
              </Link>
            </div>
            <div>
              <img src="/assets/img/icon-login.png" alt="login" />
              <Languages
                setQuestionData={setQuestionData}
                userInfo={userInfo}
                setUserLang={setUserLang}
                getImgsUrl={getImgsUrl}
                userLang={userLang}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Navbar;
