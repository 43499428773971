import { useLocation } from "react-router-dom";
import { getQuestionsDataDB, updateLanguageUserDB } from "../lib/db";
import "../styles/languages.scss";

const Languages = ({ userInfo, setQuestionData, setUserLang, userLang }) => {
  const location = useLocation();
  const changeLanguage = async (newLang) => {
    sessionStorage.setItem("userLang_IRT", newLang);
    const data = await getQuestionsDataDB(userInfo.companyId, newLang);
    const dataTextArray = Object.values(data.questions);
    Object.values(location.state.questionsData.questions).map(
      async (question, key) => {
        if (question.text) {
          question.text = dataTextArray[key].text;
        }
      }
    );
    setQuestionData(location.state.questionsData);
    setUserLang(newLang);
    await updateLanguageUserDB(userInfo, newLang);
  };
  return (
    <div className="languages-div">
      {userLang === "en" ? (
        <img
          style={{ marginRight: "4px" }}
          className="languages-icon"
          src="/assets/img/spanish.png"
          alt="es"
          onClick={() => changeLanguage("es")}
        ></img>
      ) : (
        <img
          style={{ marginRight: "4px" }}
          className="languages-icon"
          src="/assets/img/english.png"
          alt="en"
          onClick={() => changeLanguage("en")}
        ></img>
      )}
    </div>
  );
};

export default Languages;
